<template>
  <transition
    name="move-up"
  >
    <div
      v-show="showAnimation"
      class="player-container"
      :style="{ marginTop: windowTop+'px' }"
      :class="{'justify-center d-flex':showAnimation}"
    >
      <div
        id="player"
        class="lottie-player"
      />
    </div>
  </transition>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import lottie from 'lottie-web'
import brandingMixin from '@/mixins/branding'

const animations = {
  bottimmo: { animation: () => import('../../public/animations/rocket.json'), speed: [0.5, 2, 0.5] },
  markero: { animation: () => import('../../public/animations/confetti.json'), speed: [0.3, 0.3, 0.3] },
  remax: { animation: () => import('../../public/animations/balloon.json'), speed: [0.5, 2, 0.5] }
}
export default {
  mixins: [brandingMixin],

  data () {
    return {
      showAnimation: false,
      timeoutEnter: null,
      timeoutLeave: null,
      windowTop: 0
    }
  },

  async mounted () {
    window.addEventListener('scroll', this.onScroll)
    const platform = animations[this.platform]
    if (!platform) {
      return
    }
    platform.animation().then((data) => {
      this.anim = lottie.loadAnimation({
        container: document.getElementById('player'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: data
      })
      this.anim.setSpeed(platform.speed[0])
    })

    bus.$on(eventNames.SHOW_ANIMATION, () => {
      this.showAnimation = true
      this.timeoutEnter = setTimeout(() => {
        this.anim.setSpeed(platform.speed[1])
      }, 2100)
      this.timeoutLeave = setTimeout(() => {
        this.showAnimation = false
        this.anim.setSpeed(platform.speed[2])
      }, 2900)
    })
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },

  unmounted () {
    clearTimeout(this.timeoutEnter)
    clearTimeout(this.timeoutLeave)
  },

  methods: {
    onScroll (e) {
      this.windowTop = window.top.scrollY
    }
  }

}
</script>

<style scoped>
.player-container{
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  opacity: 0;
}

.lottie-player{
  height: 700px;
  width: 700px;
}

.move-up-enter-active {
    animation: move-up 3s;
}

@keyframes move-up {
    0% { opacity: 0; transform: translateY(1000px);  }
    60% { opacity: 1; transform: translateY(100px); }
    75% { opacity: 1; transform: translateY(100px);  }
    99% { opacity: 1; transform: translateY(-700px);  }
    100% { opacity: 0; transform: translateY(-700px);  }
}

</style>
